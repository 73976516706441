/*eslint-disable */
// 加载vue
import 'vue';
// 事件桥梁
import './js/eventBus';
// vuex
import 'vuex';
// vue-router
import 'vue-router';

//import './js/facebookShare';

import '../utils/toFixed4Number';
// 多语言支持
// import VueI18n from 'vue-i18n'
// Vue.use(VueI18n);
/*eslint-enable */
